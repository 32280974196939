<template>
  <div>
    <div class="container-charts">
      <div class="border-charts-main" v-if="viewChart">
        <div class="d-flex justify-center">
          <h4 class="ml-3">
            Percentual de {{ typeOfReport }} que finalizaram os lançamentos
          </h4>
        </div>
        <apexchart
          class="chart-main"
          :options="chartPercentFinishedOptions"
          :series="percentFinished"
        ></apexchart>
        <div>
          <ul
            style="
              display: flex;
              justify-content: space-between;
              list-style-type: none;
            "
          >
            <li
              v-for="releaseInfo in releasesInformations"
              :key="releaseInfo.name"
            >
              <span
                v-if="releaseInfo.name == 'Finalizados'"
                class="blueDot"
              ></span>
              <span
                v-if="releaseInfo.name == 'Não Finalizados'"
                class="redDot"
              ></span>
              <span
                v-if="releaseInfo.name == 'Não registrados'"
                class="orangeDot"
              ></span>
              <span class="span-informations">{{ releaseInfo.name }}:</span>
              <span class="span-informations">{{ releaseInfo.qty_total }}</span>
            </li>
          </ul>
        </div>
        <div>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                style="pointer-events: auto; float: right;"
                @click="downloadReport"
                class="mr-3 mt-1 iconeEditar"
              >
                mdi-cloud-download
              </v-icon>
            </template>
            <span>Relação de Municípios e Status de Lançamento</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="container-charts">
      <div class="border-charts">
        <h4>Distribuição de propostas por eixo</h4>
        <apexchart
          class="charts"
          :options="chartProposalAxieOptions"
          :series="proposalAxie"
        ></apexchart>
      </div>
      <div class="border-charts">
        <h4>Distribuição de delegados por segmento</h4>
        <apexchart
          class="charts"
          :options="chartDelegateSegmentOptions"
          :series="delegateSegment"
        ></apexchart>
      </div>
      <div class="border-charts">
        <h4>Distribuição de delegados com deficiência por deficiência</h4>
        <span style="font-size: 12px"
          >Total: {{ totalDelegatesWithDeficiences }}</span
        >
        <apexchart
          ref="realTimeBar"
          type="bar"
          class="charts"
          :options="chartDelegateDeficienciesOptions"
          :series="delegateDeficiencies"
        ></apexchart>
      </div>
    </div>
  </div>
</template>



<script>
import axios from "@/axios";

export default {
  data() {
    return {
      proposalPerAxieInformations: [],
      totalDelegatesWithDeficiences: 0,
      releasesInformations: [],
      typeOfReport: "",
      viewChart: true,
      proposalAxie: [],
      chartProposalAxieOptions: {
        legend: {
          show: true,
          position: "bottom",
        },
        noData: {
          text: "Sem dados",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "20px",
            fontFamily: undefined,
          },
        },
        colors: [
          "#6495ed",
          "#cd5c5c",
          "#ff7f50",
          "#ff69b4",
          "#32cd32",
          "#cd59ed",
          "#40e0d0",
          "#ffa500",
        ],
        labels: [],
        chart: {
          type: "pie",
        },
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
      },

      delegateSegment: [],
      chartDelegateSegmentOptions: {
        legend: {
          show: true,
          position: "bottom",
        },
        noData: {
          text: "Sem dados",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "20px",
            fontFamily: undefined,
          },
        },
        colors: [
          "#6495ed",
          "#cd5c5c",
          "#ff7f50",
          "#ff69b4",
          "#32cd32",
          "#cd59ed",
          "#40e0d0",
          "#ffa500",
        ],
        labels: [],
        chart: {
          type: "pie",
        },
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
      },

      percentFinished: [],
      chartPercentFinishedOptions: {
        noData: {
          text: "Sem dados",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#cd5c5c",
            fontSize: "14px",
            fontFamily: undefined,
          },
        },
        legend: {
          show: false,
          position: "bottom",
        },
        colors: [
          "#6495ed",
          "#cd5c5c",
          "#ffa500",
          "#ff7f50",
          "#ff69b4",
          "#32cd32",
          "#cd59ed",
          "#40e0d0",
        ],
        chart: {
          type: "pie",
        },
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
      },

      delegateDeficiencies: [
        {
          data: [],
        },
      ],
      chartDelegateDeficienciesOptions: {
        chart: {
          type: "bar",
        },
        colors: [
          "#6495ed",
          "#cd5c5c",
          "#ff7f50",
          "#ff69b4",
          "#32cd32",
          "#cd59ed",
          "#40e0d0",
          "#ffa500",
          "#fd345f",
        ],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
            "Física",
            "Visual",
            "Motora",
            "Fala",
            "Auditiva",
            "Intelectual",
            "Psicossocial",
            "Múltipla",
            "Cadeirante",
          ],
          labels: {
            style: {
              colors: [
                "#6495ed",
                "#cd5c5c",
                "#ff7f50",
                "#ff69b4",
                "#32cd32",
                "#cd59ed",
                "#40e0d0",
                "#ffa500",
              ],
              fontSize: "13px",
            },
          },
        },
      },
    };
  },

  props: {
    conference_id: {
      type: Number,
    },

    phase_id: {
      type: Number,
    },

    type_request: {
      type: String,
    },
  },

  async created() {
    await this.getProposalReports();
  },

  components: {},
  //created() {},
  methods: {
    downloadReport() {
      axios
        .get(`report/releases`, {
          params: { type_request: this.type_request },
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Relatório-finalização`;
          link.click();
          URL.revokeObjectURL(link.href);
          //fileDownload(res.data, 'relatorio.xls');
        });
    },

    getFirstTenWords(text) {
      const wordsArray = text.split(" ");
      const firstTenWords = wordsArray.slice(0, 5);
      firstTenWords[firstTenWords.length - 1] =
        firstTenWords[firstTenWords.length - 1] + "...";
      return firstTenWords.join(" ");
    },

    getProposalReports() {
      axios
        .get("proposal/get-percentual-proposal-per-axie", {
          params: {
            conference_id: this.conference_id,
            phase_id: this.phase_id,
            county_or_institute: this.type_request,
          },
        })
        .then((response) => {
          console.log(response);
          response.data.forEach((element) => {
            this.proposalAxie.push(element.percent);
            this.chartProposalAxieOptions.labels.push(
              this.getFirstTenWords(element.title)
            );
          });
        });

      axios
        .get("people-conference/get-percentual-delegate-by-segment", {
          params: {
            conference_id: this.conference_id,
            phase_id: this.phase_id,
            county_or_institute: this.type_request,
          },
        })
        .then((response) => {
          response.data.forEach((element) => {
            this.delegateSegment.push(element.percent);
            this.chartDelegateSegmentOptions.labels.push(element.name);
          });
        });

      axios
        .get("people-conference/get-delegate-per-deficiences", {
          params: {
            conference_id: this.conference_id,
            phase_id: this.phase_id,
            county_or_institute: this.type_request,
          },
        })
        .then((response) => {
          let barSeries = [];
          let labelSeriesDelegates = [];
          response.data.forEach((element) => {
            barSeries.push(Number(element.qty_delegates));
            labelSeriesDelegates.push(element.name);
          });

          this.totalDelegatesWithDeficiences = barSeries.reduce(
            (count, value) => {
              return count + value;
            },
            0
          );

          this.$refs.realTimeBar.updateSeries([
            {
              data: barSeries,
            },
          ]);
        });

      if (this.type_request == "COUNTY") {
        this.viewChart = true;
        this.typeOfReport = "Municípios";
        axios.get("county/users/reportGraph").then((response) => {
          response.data.forEach((element) => {
            this.percentFinished.push(element.percent);
            this.releasesInformations.push({
              name: element.name,
              qty_total: element.qty_total,
            });
          });
        });
      }

      if (this.type_request == "INSTITUTE") {
        this.viewChart = true;
        this.typeOfReport = "Instituições";
        axios.get("institute/users/reportGraph").then((response) => {
          response.data.forEach((element) => {
            this.percentFinished.push(element.percent);
            this.releasesInformations.push({
              name: element.name,
              qty_total: element.qty_total,
            });
          });
        });
      }

      if (this.type_request == "REGIONAL_NUCLEUS_HEALTH") {
        this.viewChart = true;
        this.typeOfReport = "Macrorregiões";
        axios
          .get("regional-nucleus-health/users/reportGraph")
          .then((response) => {
            response.data.forEach((element) => {
              this.percentFinished.push(element.percent);
              this.releasesInformations.push({
                name: element.name,
                qty_total: element.qty_total,
              });
            });
          });
      }

      if (this.type_request == "STATE") {
        this.viewChart = false;
      }
    },
  },
};
</script>

<style>
.container-charts {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@media (max-width: 1600px) {
  .chart-main {
    width: 450px;
  }
  .charts {
    width: 350px;
    height: 200px;
  }
}

@media (max-width: 1500px) {
  .chart-main {
    width: 450px;
  }
  .charts {
    width: 350px;
    height: 200px;
  }

  .container-charts {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
}

@media (min-width: 1600px) {
  .chart-main {
    width: 500px;
  }
  .charts {
    width: 400px;
    height: 300px;
  }
}
.span-informations {
  font-size: 12px;
  font-weight: 400;
  font-family: Helvetica, Arial, sans-serif;
}

.blueDot {
  margin-right: 3px;
  height: 12px;
  width: 12px;
  background-color: #6495ed;
  border-radius: 50%;
  display: inline-block;
}
.redDot {
  margin-right: 3px;
  height: 12px;
  width: 12px;
  background-color: #cd5c5c;
  border-radius: 50%;
  display: inline-block;
}
.orangeDot {
  margin-right: 3px;
  height: 12px;
  width: 12px;
  background-color: #ffa500;
  border-radius: 50%;
  display: inline-block;
}

.border-charts-main {
  text-align: center;
  width: 550px;
  height: 500px;
  border-style: solid;
  border-radius: 5%;
  border-color: #a5a5a5;
  margin: 10px;
  padding: 10px;
}

.border-charts {
  text-align: center;
  width: 450px;
  height: 360px;
  border-style: solid;
  border-radius: 5%;
  border-color: #a5a5a5;
  margin: 10px;
  padding: 10px;
}

.main-report {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}

.btn-emitir {
  display: flex;
  gap: 5px;
  align-items: center;
}

.btn-emitir > span {
  margin-left: 20px;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

.responsibility {
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.responsibility span span {
  font-weight: 500;
}

.responsibility p {
  margin: 0;
}

.responsibility p span {
  font-weight: 500;
}

.table-button-modal {
  display: flex;
  justify-content: start;
  padding-left: 8px;
  margin-top: 5px;
  align-items: center;
  flex-direction: row;
}

.table-button-modal > button {
  margin-right: 10px;
  padding: 0px 7px;
  color: white;
  cursor: pointer;
  background-color: rgb(104, 101, 101);
  border-radius: 50%;
}

.main-municipality {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-top-margin {
  margin-top: 120px !important;
}

.title-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.title-span span {
  width: auto;
  margin-right: 15px;
}

.title-span hr {
  width: 100%;
  height: 2px;
}

.municipality {
  width: 100%;
  margin-top: 15px;
}

.municipality span {
  font-weight: 500;
}

.table-dashboard {
  width: 100%;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}

.report {
  margin: 20px 20px 20px;
}
</style>
